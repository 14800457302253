/* Estilos gerais */
.payment-container {
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
    color: #2A2E4F;
    text-align: center;
    margin-bottom: 20px;
}

/* Estilo para adicionar cartão */
.delete-credit {
    color: red;
    font-size: 20px;
    margin-top: 10px;
    cursor: pointer;
}

.card-form {
    flex: 1;
    display: flex;
    flex-direction: column;

    input {
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
    }

    .form-row {
        display: flex;
        gap: 10px;
    }

    .add-card-btn {
        padding: 10px;
        background-color: #2A2E4F;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
            background-color: #1F2441;
        }
    }
}

/* Estilo para a lista de cartões */
.saved-cards {
    .card-item {
        background-color: #f9f9f9;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        p {
            margin: 5px 0;
        }

        .delete-card-btn {
            background-color: #ff4d4d;
            color: #fff;
            border: none;
            padding: 8px;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
                background-color: #ff3333;
            }
        }
    }
}

.title-payment {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5rem;
    color: #2A2E4F;
}

.card-option {
    margin-bottom: 10px;

    &.animated-card-option {
        overflow: hidden;
        max-height: 80px;
        transition: max-height 2s ease;

        &.is-open {
            max-height: 600px; // Ajuste se necessário
            opacity: 1;
        }
    }
}

.credit-card-container {
    transform-origin: 0 0;
}

@media (max-width: 992px) {
    .credit-card-container {
        transform: scale(0.8);
    }
}

@media (max-width: 768px) {
    .credit-card-container {
        transform: scale(0.6);
    }
}

@media (max-width: 576px) {
    .credit-card-container {
        transform: scale(0.8);
    }
}

@media (max-width: 400px) {
    .credit-card-container {
        transform: scale(0.6);
    }
}

.options-credit-card {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;

    &.selected {
        background-color: #e7f1ff !important;
        border-color: #99cfff !important;
    }

    &:hover {
        border-color: #007bff;
    }

    input {
        margin-right: 10px;
    }

    .radio-customized-payment {
        appearance: none;
        background-color: #fff;
        margin-right: 10px;
        border: 1px solid #ccc;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        transition: all 0.2s ease;

        &:checked {
            border-color: #007bff;
        }

        &:checked::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            background-color: #007bff;
            border-radius: 50%;
            transform: translate(-50%, -50%);
        }

        &:focus {
            outline: none;
            box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
        }
    }
}

.custom-checkbox {
    line-height: 1rem;
    color: #616379 !important;

    .checkbox-btn__checkbox-custom {
        background: white !important;
    }
}