.payment_info-title-card {
    color: #2A2E4F !important;
    font-weight: 600;
    font-size: 1.25rem;
}

.input-info-payment {
    border-color: #00AFEF !important;
}

.payment_info-title-card-2 {
    color: #2A2E4F !important;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.9rem;
}

.text-small {
    font-size: 0.875rem;
}

.payment_info .info-content {
    font-size: 1rem;
    color: #1B263B;
    font-weight: 400;
}

.payment_info .subtitle {
    font-size: 0.9rem;
    color: #1B263B;
    font-weight: 600;
}

.payment_info .table-info>td {
    background-color: #e0f7fa !important; // Custom background color for recommended row
}

.payment_info .badge-info {
    background-color: #17a2b8 !important;
}

.payment_info .table-header {
    font-size: 0.8rem;
    color: #1B263B;
    font-weight: 400;
    background-color: #f8f9fa; // Background color for the header
    border-color: #f8f9fa;
}

.payment_info .table {
    border-color: #f8f9fa !important;
}

.payment_info .table thead th {
    background-color: #f8f9fa; // Lighter background color for the header
    color: #1B263B; // Dark text color for the header
    border-color: #f8f9fa; // Light border color for the header
}

.payment_info .table-bordered td {
    color: #1B263B; // Dark text color for the header
    align-items: center !important;
    border-color: #f8f9fa !important;
}

.table-button-payment {
    padding: 0px 16px;
    margin: 0 !important;
    background-color: white !important;
    border-color: #1B263B !important;
    color: #1B263B !important;
    font-weight: 500;

    &:hover {
        background-color: white !important;
        color: #1B263B !important;
    }
}

.table-button-payment-warning {
    padding: 0px 16px;
    margin: 0 !important;
    background-color: #FFB74D !important;
    border-color: #FFB74D !important;
    color: #1B263B !important;
    font-weight: 500;

    &:hover {
        background-color: #FFB74D !important;
        color: #1B263B !important;
    }
}

.table-button-payment-change {
    padding: 2px 20px;
    margin: 0 !important;
    background-color: #1B263B !important;
    border-color: #1B263B !important;
    color: white !important;
    font-weight: 500;
    float: right;

    &:hover {
        background-color: #1B263B !important;
        color: white !important;
    }

    &:active {
        background-color: #1B263B !important;
        color: white !important;
    }
}