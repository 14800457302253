.balance-badge {
  background-color: #142357;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-top: 0.4rem;
  border: 2px solid #E8EAF6;

  .wallet-icon {
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
  }

  .balance-amount {
    font-size: 1rem;
    line-height: 1rem;
    color: #E8EAF6;
    margin-right: 0.5rem;
  }

  .add-button {
    font-size: 1rem !important;
    margin: 0 !important;
    padding: 0.001rem 0.425rem 0.1rem 0.425rem;
    background-color: #324A7B;
    border-color: #324A7B;
  }
}

.balance-value {
  color: #053061;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1rem;
}