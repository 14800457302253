.invoice {

    .clearfix {
        display: block;
        clear: both;
    }

    .hidden {
        display: none;
    }

    b,
    strong,
    .bold {
        font-weight: bold;
    }

    #container {
        font: normal 13px/1.4em "Inter", Sans-serif;
        margin: 0 auto;
        min-height: 816px;
        background: #DBF5FF url("../../shared/img/invoice/bg.png") 0 0 no-repeat;
        background-size: 100% auto;
        color: #5B6165;
        position: relative;
    }

    #memo {
        padding-top: 15px !important;
        margin: 10px 110px 10px 60px;
        border-bottom: 1px solid #ddd;
        height: 115px;
    }

    #memo .logo {
        float: left;
        margin-right: 20px;
    }

    #memo .logo img {
        width: 150px;
        height: 100px;
        object-fit: contain;
    }

    #memo .company-info {
        float: right;
        text-align: right;
        padding-top: 14px;
    }

    #memo .company-info>div:first-child {
        line-height: 1em;
        font-weight: bold;
        font-size: 22px;
        color: #0B1039;
    }

    #memo .company-info span {
        font-size: 11px;
        min-width: 20px;
    }

    #memo:after {
        content: '';
        display: block;
        clear: both;
    }

    #invoice-title-number {
        font-weight: bold;
        margin: 30px 0;
    }

    #invoice-title-number span {
        line-height: 0.88em;
        display: inline-block;
        min-width: 20px;
    }

    #invoice-title-number #title {
        text-transform: uppercase;
        padding: 0px 2px 0px 60px;
        font-size: 50px;
        background: #00AFEF;
        color: white;
    }

    #invoice-title-number #number {
        margin-left: 10px;
        font-size: 35px;
        position: relative;
        top: -5px;
    }

    #client-info {
        float: left;
        margin-left: 60px;
        min-width: 220px;
    }

    #client-info>div {
        margin-bottom: 3px;
        min-width: 20px;
    }

    #client-info span {
        display: block;
        min-width: 20px;
    }

    table {
        table-layout: fixed;
    }

    table th,
    table td {
        vertical-align: top;
        word-break: keep-all;
        word-wrap: break-word;
    }

    #items {
        margin: 35px 30px 0 30px;
    }

    #items .first-cell,
    #items table th:first-child,
    #items table td:first-child {
        width: 40px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        text-align: right;
    }

    #items table {
        border-collapse: separate;
        width: 100%;
    }

    #items table th {
        font-weight: bold;
        padding: 5px 8px;
        text-align: right;
        background: #0B1039;
        color: white;
        text-transform: uppercase;
    }

    #items table th:nth-child(2) {
        width: 30%;
        text-align: left;
    }

    #items table th:last-child {
        text-align: right;
    }

    #items table td {
        padding: 9px 8px;
        text-align: right;
        border-bottom: 1px solid #ddd;
    }

    #items table td:nth-child(2) {
        text-align: left;
    }

    #sums {
        margin: 94px 30px 0 0;
        background: url("../../shared/img/invoice/total-stripe-firebrick.png") right bottom no-repeat;
    }

    #sums table {
        float: right;
    }

    #sums table tr th,
    #sums table tr td {
        min-width: 100px;
        padding: 9px 8px;
        text-align: right;
    }

    #sums table tr th {
        font-weight: bold;
        text-align: left;
        padding-right: 35px;
    }

    #sums table tr td.last {
        min-width: 0 !important;
        max-width: 0 !important;
        width: 0 !important;
        padding: 0 !important;
        border: none !important;
    }

    #sums table tr.amount-total th {
        text-transform: uppercase;
    }

    #sums table tr.amount-total th,
    #sums table tr.amount-total td {
        font-size: 15px;
        font-weight: bold;
    }

    #sums table tr:last-child th {
        text-transform: uppercase;
    }

    #sums table tr:last-child th,
    #sums table tr:last-child td {
        font-size: 15px;
        font-weight: bold;
        color: white;
    }

    #invoice-info {
        float: left;
        margin: -70px 90px 0 54px;
    }

    #invoice-info>div>span {
        display: inline-block;
        min-width: 20px;
        min-height: 18px;
        margin-bottom: 3px;
    }

    #invoice-info>div>span:first-child {
        font-weight: bold;
        font-size: 18px;
        line-height: 100%;
        color: #2A2E4F;
    }

    #invoice-info>div>span:last-child {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        color: #2A2E4F;
    }

    #invoice-info:after {
        content: '';
        display: block;
        clear: both;
    }

    #terms {
        float: left;
        margin-top: 50px;
    }

    #terms .notes {
        min-height: 30px;
        min-width: 50px;
        color: #B32C39;
    }

    #terms .payment-info div {
        margin-bottom: 3px;
        min-width: 20px;
    }

    .thank-you {
        margin: 10px 0 30px 0;
        display: inline-block;
        min-width: 20px;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 0.88em;
        padding: 0px 30px 0px 2px;
        font-size: 50px;
        background: #00AFEF;
        color: white;
    }

    .ib_bottom_row_commands {
        margin-left: 30px !important;
    }

    /**
   * If the printed invoice is not looking as expected you may tune up
   * the print styles (you can use !important to override styles)
   */
    @media print {
        #container {
            height: 100%;
        }
    }
}