.pricing {
    .cards {
        // display: flex;
        font-family: 'Inter', sans-serif;
        // flex-wrap: wrap;
        padding: 0 70px;

    }

    .card-pricing {
        background: #fff;
        color: #2a2e4f;
        border-radius: 0.8rem;
        border: 1px solid rgba(0, 0, 0, .125)
    }

    .cards .card-pricing.active {
        background: linear-gradient(135deg, #5ac8fa 0%, #007aff 100%);
        color: #fff;
        align-items: center;
        // transform: scale(1.1);
        z-index: 1;
    }

    .inside {
        margin: 1.4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .inside li {
        list-style-type: none;
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 1rem 0;
    }

    .inside li.price {
        color: #2A2E4F;
        font-size: 2.6rem;
        padding: 0;
        padding-top: 1rem;
        width: 250px;
    }

    .pack {
        font-size: 1.4rem;
        font-weight: 600;
    }

    .shadow {
        box-shadow: -5px 5px 15px 1px rgba(0, 0, 0, 0.1);
    }

    .card-pricing.active .price {
        color: #fff;
    }

    .recommend {
        transform: rotate(45deg);
        position: absolute;
        right: 23px;
        background: #2a2e4f;
        width: 245px;
        color: white;
        text-align: center;
        font-weight: 600;
        top: 52px;
        -webkit-clip-path: polygon(19% 0%, 79% 0%, 88% 100%, 10% 100%);
        clip-path: polygon(18% 0%, 80% 0%, 89% 100%, 10% 100%);
    }

    .btn {
        margin-top: 1rem;
        height: 2.6rem;
        width: 13.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #142357;
        color: #fff;
        outline: none;
        border: 0;
        font-weight: 500 !important;
    }

    .active-btn {
        background: #fff;
        color: #007bff;

        &:before {
            background-color: #060c2f;
            color: #fff;
        }

        &:hover,
        &:focus,
        &:active,
        &:active:focus {
            background-color: #060c2f;
            color: #fff;
        }
    }

    .bottom-bar {
        border-bottom: 2px solid hsla(240, 8%, 85%, 0.582);
    }

    .card-pricing.active .bottom-bar {
        border-bottom: 2px solid hsla(240, 8%, 85%, 0.253);
    }

    @media (max-width: 280px) {
        .inside {
            margin: 1rem;
        }
    }

    @media (max-width: 280px) {
        ul {
            margin: 1rem;
        }

        h1 {
            font-size: 1.2rem;
        }

        .toggle {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            height: 80px;
        }

        .cards {
            margin: 0;
            display: flex;
            flex-direction: column;
        }

        .card-pricing {
            transform: scale(0.8);
            margin-bottom: 1rem;
        }
    }

    @media (min-width: 280px) and (max-width: 320px) {
        .cards {
            padding: 0 !important;
        }

        .inside {
            margin: 20px;
        }
    }

    @media (min-width: 280px) and (max-width: 320px) {
        ul {
            margin: 20px;
        }

        .cards {
            padding: 0 !important;
        }

        .card-pricing {
            margin-bottom: 1rem;
        }

        .cards .card-pricing.active {
            transform: scale(1);
        }
    }

    @media (min-width: 320px) and (max-width: 414px) {
        .cards {
            padding: 0 !important;
        }

        .recommend{
            right: -44px  !important;
            width: 167px  !important;
            font-size: 0.6rem !important;
            top: 33px;
            -webkit-clip-path: polygon(19% 0%, 79% 0%, 88% 100%, 10% 100%);
            clip-path: polygon(18% 0%, 80% 0%, 89% 100%, 10% 100%);
        }

        .card-pricing {
            margin-bottom: 1rem;
        }

        .cards .card-pricing.active {
            transform: scale(1);
        }
    }

    @media (min-width: 414px) and (max-width: 768px) {
        .cards {
            padding: 0 !important;
        }

        .recommend{
            right: -56px  !important;
            width: 216px  !important;
            font-size: 0.6rem !important;
            top: 47px;
            -webkit-clip-path: polygon(19% 0%, 79% 0%, 88% 100%, 10% 100%);
            clip-path: polygon(18% 0%, 80% 0%, 89% 100%, 10% 100%);
        }

        .card-pricing {
            margin-bottom: 1rem;
            margin-right: 1rem;
        }

        .cards .card-pricing.active {
            transform: scale(1);
        }
    }

    @media (min-width: 768px) and (max-width: 1046px) {
        .cards {
            display: flex;
            padding: 0 !important;
        }

        .recommend{
            right: -52px  !important;
            width: 201px  !important;
            font-size: 0.6rem !important;
            top: 42px !important;
            -webkit-clip-path: polygon(19% 0%, 79% 0%, 88% 100%, 10% 100%);
            clip-path: polygon(18% 0%, 80% 0%, 89% 100%, 10% 100%);
        }

        .card-pricing {
            margin-bottom: 1rem;
            margin-right: 1rem;
        }

        .cards .card-pricing.active {
            transform: scale(1);
        }

    }
}