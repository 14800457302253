.load {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  position: fixed;
  background: #ffffff;
  z-index: 1000;

  &+div {
    height: 100vh;
    overflow: hidden;
  }

  &.loaded {
    animation: ease loaded 0.5s;
  }
}


.panel__body {
  position: relative;

  .panel__btns {
    position: absolute;
    top: 30px;
    right: 42px;

    svg {
      fill: $color-white;
    }
  }


  .panel__btn {
    width: 14px;
    height: 14px;
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;

    margin-left: 5px;

    svg {
      width: 24px;
      height: 24px;
      fill: $color-accent-hover;

      &:hover {
        animation: rotating 2s linear infinite;
      }
    }

    &:focus {
      outline: none;
    }
  }


  .panel__title {
    text-transform: uppercase;
    margin-bottom: 30px;
    transition: 0.3s;

    h5 {
      font-size: 13px;
    }

  }
}


.panel__refresh {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    top: 0;
    left: 0;
    border-radius: 5px;

    @include themify($themes) {
      background-color: themed('colorBackground');
      opacity: 0.8;
    }
  }

  svg {
    fill: $color-accent;
    position: absolute;
    animation: refresh 1s infinite linear;
    z-index: 10;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
  }

  @keyframes refresh {

    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

.sweet-loading {
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: center;
  width: 326px;
}

.load__icon {
  animation: linear load 2s infinite;
  margin: auto;
  width: 32px;
  height: 32px;
}

.load__icon-wrap {
  margin: auto;
}

@keyframes load {
  from {
    transform: rotate(0deg) scale(2);
  }

  to {
    transform: rotate(360deg) scale(2);
  }
}

@keyframes loaded {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}