.dashboard__health-chart-card {
  text-align: center;
}

.dashboard__health-chart {
  position: relative;
}

.dashboard__health-chart-info {
  position: absolute;
  width: 100%;
  top: calc(50% - 55px);
  animation: label 1.5s ease-in;

  p {
    margin: 0;
  }
}

.dashboard__health-chart-number {
  font-size: 56px;
  line-height: 60px;

  @include themify($themes) {
    color: themed('colorFitness');
  }
}

.dashboard__health-chart-units {
  color: $color-additional;
  margin-top: 5px;
}

.dashboard__chart-pie--commerce,
.dashboard__chart-pie--fitness {
  .recharts-legend-wrapper {
    @media screen and (min-width: 370px) {
      bottom: 0px !important;
    }

    @media screen and (min-width: 700px) {
      bottom: 70px !important;
    }

    @media screen and (min-width: 1020px) {
      bottom: -30px !important;
    }

    @media screen and (min-width: 1200px) {
      bottom: -55px !important;
    }

    @media screen and (min-width: 1400px) {
      bottom: -25px !important;
    }

    @media screen and (min-width: 1800px) {
      bottom: -30px !important;
    }
  }
}

.dashboard__chart-pie {
  text-align: 'left';

  height: 280px !important;

  .recharts-surface {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 768px) {

    &.dashboard__chart-pie--crypto {
      height: 300px !important;

      .dashboard__chart-pie-container {
        height: 350px !important;
      }
    }

    &.dashboard__chart-pie--commerce {
      height: 360px !important;

      .dashboard__chart-pie-container {
        height: 410px !important;
      }
    }

    &.dashboard__chart-pie--fitness {
      height: 360px !important;

      .dashboard__chart-pie-container {
        height: 410px !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .recharts-legend-wrapper {
      position: relative !important;
    }
  }

  @media screen and (max-width: 480px) {

    &.dashboard__chart-pie--crypto {
      height: 240px !important;

      .dashboard__chart-pie-container {
        height: 230px !important;
      }

      .dashboard__chart-legend {
        margin-left: -10px;
      }

      li {
        margin-top: 0;
      }
    }
  }
}

.dashboard__chart-pie-container {

  padding-left: 0px;
  height: 200px !important;
}

.dashboard__chart-legend {
  padding: 0;
  list-style: none;

  span {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;

    margin-right: 10px;

  }

  li {
    margin-top: 5px;
    text-align: left;

    @include themify($themes) {
      color: themed('colorText');
    }
  }
}

.dashboard__current-users {
  display: flex;
  align-items: center;
  flex-direction: column;

  .dashboard__current-users-chart {

    position: relative;
    width: 100%;

    div,
    svg {
      width: 100% !important;
      margin: 0 auto;
    }

    .dashboard__current-users-label {
      margin: 0;
      position: absolute;
      top: calc(50% + 10px);
      text-align: center;

      left: calc(50% - 58px);
      font-size: 38px;
      width: 120px;
    }
  }

  .dashboard__current-users-info {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    font-size: 13px;
    line-height: 1.54;
    color: $color-gray;

    p {
      margin: 0;
    }

    .dashboard__current-users-day {

      display: flex;
      flex-direction: column;
      align-items: center;

      & span:last-child {
        font-size: 16px;
        line-height: 1.25;
        font-weight: 500;
        color: $color-gray;
      }
    }
  }

  @media screen and (min-width: 1539px) {
    .dashboard__current-users-info {
      padding: 0 20px;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1539px) {

    .dashboard__current-users-chart {

      svg,
      div {
        height: 100px !important;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {

    .dashboard__current-users-info {
      padding: 0 75px;
    }
  }

}

.dashboard__card-widget {
  padding-top: 25px;
  padding-bottom: 25px;
}

.mobile-app-widget {

  .mobile-app-widget__top-line {
    display: flex;

    .mobile-app-widget__total-stat {
      direction: ltr;
      padding: 0;
      font-size: 28px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      margin-right: auto;
      line-height: normal;
    }
  }

  .mobile-app-widget__top-line--pink {
    .mobile-app-widget__total-stat {
      color: $color-red;
    }
  }

  .mobile-app-widget__top-line--red {
    .mobile-app-widget__total-stat {
      color: $color-red;
    }
  }

  .mobile-app-widget__top-line--lime {
    .mobile-app-widget__total-stat {
      color: #b8e986;
    }
  }

  .mobile-app-widget__top-line--blue {
    .mobile-app-widget__total-stat {
      color: #053061;
    }
  }

  .mobile-app-widget__top-line--turquoise {
    .mobile-app-widget__total-stat {
      color: #4ce1b6;
    }
  }

  .mobile-app-widget__top-line--green {
    .mobile-app-widget__total-stat {
      color: $color-green;
    }
  }

  .mobile-app-widget__top-line--yellow {
    .mobile-app-widget__total-stat {
      color: #f6da6e;
    }
  }

  .mobile-app-widget__title {
    text-align: left;
    text-transform: capitalize;
    position: relative;
    margin: 0 0 20px;

    h5 {
      font-size: 12px;
      color: $color-gray;
      font-weight: 500;
    }
  }

  .dashboard__trend-icon {
    margin: 0;
    height: 29px;
    min-width: 28px;
    fill: $color-light-gray;
  }

  .progress__label {
    font-size: 14px;
    font-weight: 500;
  }

  .progress__label {
    right: -7px;
  }

  .progress-bar {
    border-radius: 5px;
    background-color: unset;
  }

}

@keyframes label {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}