#svg-map .blocked path {
    fill: #616379;
    pointer-events: none;
}

#svg-map text {
    fill: #fff;
    font: 12px Inter, sans-serif;
    cursor: pointer;
}

#svg-map .blocked text {
    fill: #fff;
    font: 12px Inter, sans-serif;
    cursor: pointer;
    pointer-events: none;
}

#svg-map .estado {
    text-decoration: none;
}

#svg-map .blocked {
    text-decoration: none;
    pointer-events: none;
}

#svg-map .estado:hover {
    cursor: pointer;
    text-decoration: none;
}

// #svg-map .estado .circle {
//     fill: #c8291d;
// }

#svg-map .blocked .circle {
    fill: #767782;
}

#svg-map .estado:hover .circle {
    cursor: pointer;
    box-shadow: 0 0.5em 0.5em -0.4em;
    transform: translateY(-0.25em);
}

#svg-map .estado:hover path {
    box-shadow: 0 0.5em 0.5em -0.4em;
    transform: translateY(-0.25em);
}