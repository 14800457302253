.checkout {
    background-color: #E0E5E5;

    h5 {
        font-size: 1.25rem;
        color: #212529;
    }

    span{
        font-size: 16px;
    }

    .card {
        border: none
    }

    .invoice {
        background: white;
    }

    .logo {
        background-color: #142357;
    }

    .totals tr td {
        font-size: 13px
    }

    .footer {
        background-color: #eeeeeea8
    }

    .footer span {
        font-size: 12px
    }

    .product-qty span {
        font-size: 12px;
        color: #dedbdb
    }
}