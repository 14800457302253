.payment-container {
    h2 {
        color: #2A2E4F;
        font-weight: 600;
        line-height: 2.5rem;
    }

    strong {
        color: #2A2E4F !important;
    }
}

.payment-image {
    width: 100%;
    max-width: 300px;
    margin-bottom: 15px;
    border-radius: 5px;
    object-fit: cover;
}

h2 {
    font-size: 1.8rem;
    color: #333;
}

.payment-info {
    margin-top: 10px;
    text-align: center;
    font-size: 1rem;
    color: #264653 !important;
}

.barcode-section {
    margin-top: 20px;
    width: 100%;
    text-align: center;

    p {
        font-size: 1rem;
        margin-bottom: 8px;
        color: #333;
        word-break: break-word;
    }
}

.copy-button,
.view-boleto-button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px 15px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.copy-button {
    background-color: #2A2E4F;
    color: #fff;
    border-radius: 0%;

    &:hover {
        background-color: #2A2E4F;
    }
}

.view-boleto-button {
    margin-top: 15px;
    background-color: #28a745;
    color: #fff;
    border-radius: 0%;

    &:hover {
        background-color: #218838;
    }
}

.extra-info {
    margin-top: 20px;
    font-size: 0.9rem;
    color: #264653 !important;
    text-align: center;

    p {
        color: #264653 !important;
    }
}