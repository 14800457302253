.payment-options .payment-card {
  cursor: pointer;
  transition: background-color 0.3s, border 0.3s;
  border: 1px solid #2A2E4F;
  border-radius: 8px;
  padding: 1rem;
}

.payment-options .payment-card.selected {
  background-color: #e7f1ff; // Cor de fundo ao selecionar
  border-color: #007bff; // Cor da borda ao selecionar
}

.payment-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.input-group-pix .form-control {
  border-right: none;
}

.input-group-pix .btn {
  border-left: none;
}

.copy-field {
  border: 1px dashed #d3d3d3;
  padding: 12px 0;
  font-size: 16px;
  color: #2a2e4f;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.text-pix {
  color: #343a40
}

.text-muted {
  color: #6c757d !important;
  /* Cor para o texto secundário */
}

.text-primary-custom {
  color: #003366 !important;
  /* Azul escuro para o título principal */
}

.text-info-custom {
  color: #555555 !important;
  /* Cinza escuro para o texto informativo */
}

.text-secondary-custom {
  color: #777777 !important;
  /* Cinza claro para o texto secundário */
}