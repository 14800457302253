.badge {
    display: inline-block;
    padding: 0.4em 0.4em;
    font-size: 90%;
    font-weight: 600;
    line-height: 1rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.redoc-wrap {
    font-family: "Inter" !important;
}

.sc-eVspGN.menu-content {
    font-family: "Inter" !important;
    top: 60px !important;
}

.content {
    font-size: 14px;
    line-height: 27px;
    color: #616379;
    border-bottom: 1px solid #EFEFF2;
}

.text-content {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #2A2E4F !important;
}

.informations {
    transition: opacity 4s ease-in;
}

.Toastify__toast-body {
    color: #fff !important;
    font-family: 'Inter', sans-serif !important;
    text-align: center;
}

.custom-select {
    background: #053061 !important;
    color: white !important;
    font-family: 'Inter', sans-serif !important;
    border-radius: 22px !important;
    text-align: center !important;
    font-size: 14px !important;
    line-height: 1.2;
}

.page-link {
    color: #053061 !important;
    font-family: 'Inter' !important;
}

.page-item.active .page-link {
    background-color: #053061;
    border-color: #053061;
    color: white !important;
}

.equal-width th,
.equal-width td {
    width: 20%;
    /* Ajuste conforme necessário para garantir que a soma das larguras seja 100% */
}